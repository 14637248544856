export const MenuItems =[
        {
            id:"1",
            menu_name:"Events",
            menu_icon:"fa fa-calendar",
            path:"/"

        },
        {
            id:"2",
            menu_name:"Point",
            menu_icon:"fa fa-map-marker",
            path:"/Point"

        },

 
    ]

  