import React, { useEffect, useState } from "react";
import axios from '../../axios';
import ModalPortal from "../../portal/ModalPortal";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../styles/ModalStyle"
import { FaTimes } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";

const PointOpeningModal = (props) => {
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState();
    const [features, setFeatures] = useState();

    const [addList, setAddList] = useState({
        id:props?.data?.id,
        point_id:props?.pointId,
    })
    const formattedDefaultStartTime = props?.data?.opening_hour ? props?.data?.opening_hour?.slice(11, 16) : '';
    const formattedDefaultEndTime = props?.data?.ending_hour ? props?.data?.ending_hour?.slice(11, 16) : '';

    useEffect(()=>{
        if(props?.type === 'add'){
            setIsOpen(true);
        }else{
            setIsOpen(false)
        }
    },[props?.isOpen])


    const handleEdit = (isEdit) => {
        if (isEdit) {
            setEdit(false);
            setIsAdd(false);
            setDelete(false);
        } else {
            setEdit(true);
            setIsAdd(false);
            setDelete(false);
        }
    }
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            props?.handleModalClose()
        }
    })


    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
    
        setAddList((prevAddList) => ({
            ...prevAddList,
            [name]: type === 'checkbox' ? checked : value,
        }));
    }

 
  const getFeaturesData = () => {
    axios.get(`/weeks/all`).then(
      res => {
          setFeatures(res?.data);

      }
    ).catch(err => { console.log('Errori', err) });
  }

useEffect(()=>{
    getFeaturesData();
},[])


  const handleList = (e) => {
    e.preventDefault();
   
        axios.post('/point-opening', addList)
        .then(data => {
            props?.handleModalClose();
            props?.getData();
            toast.success('Të dhënat e ores janë shtuar me sukses!');
        }
    ).catch(err => {
        toast.error('Te dhenat nuk jane shtuar!');
        console.log(err);
    });

  }
  const handleEditList = (e) => {
    e.preventDefault();
    axios.put('/point-opening/update', addList)
    .then(data => {
        props?.handleModalClose();
        props?.getData();
        toast.success('Të dhënat e ores janë edituar me sukses!');
    }
).catch(err => {
    toast.error('Te dhenat nuk jane shtuar!');
    console.log(err);
});

  }



    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle>
                        
                        <ModalButtonsPosition>
                            {props?.type !== "add" ? (
                                <>
                                    <ModalHandleButtons edit onClick={() => handleEdit(isEdit)} >
                                        {isEdit ? <FaTimes role="button" /> : <FaEdit role="button" />}
                                    </ModalHandleButtons>

                                    {!isEdit &&
                                        <ModalHandleButtons close onClick={() => props?.handleModalClose()} >
                                            <FaTimes role="button" />
                                        </ModalHandleButtons>}

                                </>
                            ) : (
                                <ModalHandleButtons
                                    close
                                    onClick={() => props?.handleModalClose()}
                                ><FaTimes role="button" />
                                </ModalHandleButtons>
                            )}
                        </ModalButtonsPosition>
                        <h4 className="text-center">{props?.type !== 'add' ? "Edit Day" : "Add Day"}</h4>
               
                        <ModalForm onSubmit={
                            props?.type === 'add' ? handleList : handleEditList}
                        >
                           
                        
                            <div class="mb-3">
                                <label htmlFor="">Select Day</label>
                              <select className="form-control"
                                onChange={handleChange}
                                required
                               name="week_id"
                               disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                placeholder="test"
                                     >
                                  <option value="" selected disabled>Select Day</option>
                                  {features?.map((el) => {
                                        return <option value={Number(el?.id)} id={el?.id} selected={Number(props?.data?.week_id) == el?.id ? "selected" : false}>{el?.name}</option>
                                    })}
                                </select>
                            </div>
                            <div className="row my-3">
                                    <div className="col-lg-6 col-md-3 col-12 text-dark">
                                        <span className="text-dark">Start time</span>
                                        <input name="opening_hour" type="time" className="form-control" onChange={handleChange}
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            defaultValue={formattedDefaultStartTime}
                                             />
                                    </div>
                                    <div className="col-lg-6 col-md-3 col-12 text-dark">
                                        <span className="text-dark">End time</span>
                                        <input name="ending_hour" type="time" className="form-control" onChange={handleChange}
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            defaultValue={formattedDefaultEndTime}
                                           
                                        />
                                    </div>
                                </div>
                                <div class="form-check my-2">
                            <input onChange={handleChange} defaultChecked={props?.data?.is_open} name="is_open" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                            <label class="form-check-label" for="flexCheckChecked">
                                Is Open
                            </label>
                            </div>
                            {isEdit == false && props?.type != "add" ? "" :
                               <ModalButtonsPosition>
                                    
                               <ModalMainButtons type="submit"  disabled={submitting}>{props?.type === 'add' ? "Shto" : "Edit"}</ModalMainButtons>
                           </ModalButtonsPosition>
                            }
                        </ModalForm>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }

}
export default PointOpeningModal