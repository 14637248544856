import React from 'react'
import "./front.css"
import { useEffect, useState } from 'react'
import brezovica from './img/kosovo/brezovica.jpg';
import About2 from './img/about-2.jpg';
import logoCiceroni from './img/ciceroniLogo.svg';
import mainImage from "./img/kosovo/prizren.jpg"
import axios from '../../axios';
const FrontPage = () => {

  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [horecaData, setHorecaData] = useState();
  const [total, setTotal] = useState();
  const [horecaRestaurant, setHorecaRestaurant] = useState();
  const [horecaCaffe, setHorecaCaffe] = useState();
  const [idHoreca, setIdHoreca] = useState(8);


  const getData = () =>{
     axios.get(`/home/all/${idHoreca}`).then((data)=>{
      setHorecaData(data.data);
    }).catch((err)=>{
      console.log(err)
    })
  }
  const getTotal = () =>{
     axios.get(`/home/totalNumber`).then((data)=>{
      setTotal(data?.data);
      console.log("totali",data?.data)
    }).catch((err)=>{
      console.log(err)
    })
  }



  useEffect(()=>{
    getData();
    getTotal()
  },[idHoreca])

  useEffect(()=>{
    getTotal()
  },[])

 

  const handleMobileNavToggle = () => {
    setMobileNavActive(!mobileNavActive);
  };

  useEffect(() => {
    const navbarLinks = document.querySelectorAll('#navbar a');

    const navbarLinkClickHandler = () => {
      if (mobileNavActive) {
        handleMobileNavToggle();
      }
    };

    navbarLinks.forEach((navbarlink) => {
      if (!navbarlink.hash) return;

      let section = document.querySelector(navbarlink.hash);
      if (!section) return;

      navbarlink.addEventListener('click', navbarLinkClickHandler);
    });

    return () => {
      navbarLinks.forEach((navbarlink) => {
        navbarlink.removeEventListener('click', navbarLinkClickHandler);
      });
    };
  }, [mobileNavActive]);

  useEffect(() => {


    const selectHeader = document.querySelector('#header');
    if (selectHeader) {
      document.addEventListener('scroll', () => {
        window.scrollY > 100 ? selectHeader.classList.add('sticked') : selectHeader.classList.remove('sticked');
      });
    }

    let navbarlinks = document.querySelectorAll('#navbar a');

    function navbarlinksActive() {
      navbarlinks.forEach(navbarlink => {

        if (!navbarlink.hash) return;

        let section = document.querySelector(navbarlink.hash);
        if (!section) return;

        let position = window.scrollY + 200;

        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    }

    window.addEventListener('load', navbarlinksActive);
    document.addEventListener('scroll', navbarlinksActive);

    //Scroll Top when scroll Down
    const scrollTop = document.querySelector('.scroll-top');
    if (scrollTop) {
      const togglescrollTop = function () {
        window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
      };
      window.addEventListener('load', togglescrollTop);
      document.addEventListener('scroll', togglescrollTop);
      scrollTop.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      });
    }

    // Clean up event listeners or perform any necessary cleanup

    return () => {
      window.removeEventListener('load', navbarlinksActive);
      document.removeEventListener('scroll', navbarlinksActive);
    };
  }, []);

  return (
    <>
      <body>

        <header id="header" className={`header fixed-top d-flex align-items-center ${mobileNavActive ? 'mobile-nav-active' : ''}`}>
          <div className="container d-flex align-items-center justify-content-between">

            <a href="/" className="d-flex align-items-center me-auto me-lg-0">
              <img src={logoCiceroni} alt="" className='w-75' />
              {/* <h1>Ciceron<span>.</span></h1> */}
            </a>

            <nav id="navbar" className={`navbar ${mobileNavActive ? 'mobile-nav-active' : ''}`}>
              <ul>
                <li><a href="#hero">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#whyUs">Why us</a></li>
                <li><a href="#horeca">Horeca</a></li>
                <li><a href="#team">Subscription</a></li>
      
                <li><a href="#contact">Contact</a></li>
              </ul>
            </nav>

            <a className="btn-book-a-table" href="/login">Kyçu</a>

            <i className={`mobile-nav-toggle mobile-nav-show fa fa-bars ${mobileNavActive ? 'd-none' : ''}`} onClick={handleMobileNavToggle}></i>
            <i className={`mobile-nav-toggle mobile-nav-hide fa fa-times ${mobileNavActive ? '' : 'd-none'}`} onClick={handleMobileNavToggle}></i>

          </div>
        </header>


        <section id="hero" className="hero d-flex align-items-center section-bg">
          <div className="container">
            <div className="row justify-content-between gy-5">
              <div className="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start">
                <h2 data-aos="fade-up">Lorem ipsum dolor<br />sit amet</h2>
                <p data-aos="fade-up" data-aos-delay="100">Sed autem laudantium dolores. Voluptatem itaque ea consequatur eveniet. Eum quas beatae cumque eum quaerat.</p>
                <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                  {/* <a href="/login" className="btn-book-a-table">Kyçu</a> */}
                  <a href="https://www.youtube.com/watch?v=eUIOL74aAQo" target='_blank' className="glightbox btn-watch-video d-flex align-items-center m-0"><i className="fa fa-play-circle"></i><span>Watch Video</span></a>
                </div>
              </div>
              <div className="col-lg-5 order-1 order-lg-2 text-center text-lg-start">
                <img src={mainImage} className="img-fluid img-main" alt="" data-aos="zoom-out" data-aos-delay="300" />
              </div>
            </div>
          </div>
        </section>

        <main id="main">


          <section id="about" className="about">
            <div className="container" data-aos="fade-up">

              <div className="section-header">
                <h2>About Us</h2>
                <p>Learn More <span>About Us</span></p>
              </div>

              <div className="row gy-4">
                <div className="col-lg-7 position-relative about-img"
                  style={{
                    backgroundImage: `url(${brezovica})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  }} data-aos="fade-up" data-aos-delay="150">
               
                </div>
                <div className="col-lg-5 d-flex align-items-end" data-aos="fade-up" data-aos-delay="300">
                  <div className="content ps-0 ps-lg-5">
                    <p className="fst-italic">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                      magna aliqua.
                    </p>
                    <ul>
                      <li><i className="fa fa-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                      <li><i className="fa fa-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                      <li><i className="fa fa-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                    </ul>
                    <p>
                      Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
                    </p>

                    <div className="position-relative mt-4">
                      <img src={About2} className="img-fluid" alt="" />
                      <a href="https://www.youtube.com/watch?v=pI35F70gFio" target="_blank" className="glightbox play-btn"></a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>


          <section id="whyUs" className="why-us section-bg">
            <div className="container" data-aos="fade-up">
            <div className="section-header">
                {/* <h2>HORECA</h2> */}
                <p>Why <span>us</span></p>
              </div>
              <div className="row gy-4">

                <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                  <div className="why-box">
                    <h3>Why Choose Ciceron?</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                      Asperiores dolores sed et. Tenetur quia eos. Autem tempore quibusdam vel necessitatibus optio ad corporis.
                    </p>
                    <div className="text-center">
                      <a href="#" className="more-btn">Learn More <i className="bx bx-chevron-right"></i></a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-8 d-flex align-items-center">
                  <div className="row gy-4">

                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="200">
                      <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                        <i className="fa fa-clipboard"></i>
                        <h4>Corporis voluptates officia eiusmod</h4>
                        <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                      </div>
                    </div>

                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                      <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                        <i className="fa fa-gem"></i>
                        <h4>Ullamco laboris ladore pan</h4>
                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                      </div>
                    </div>

                    <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                      <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                        <i className="fa fa-inbox"></i>
                        <h4>Labore consequatur incidid dolore</h4>
                        <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </section>


          <section id="stats-counter" className="stats-counter">
            <div className="container" data-aos="zoom-out">

              <div className="row gy-4">

                <div className="col-lg-3 col-md-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span className="purecounter">{total?.userslength}</span>
                    <p>Clients</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span className="purecounter">{total?.citieslength}</span>
                    <p>Cities</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span className="purecounter">{total?.eventslength}</span>
                    <p>Events</p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span className="purecounter">{total?.pointslength}</span>
                    <p>Points</p>
                  </div>
                </div>

              </div>

            </div>
          </section>


          <section id="horeca" className="menu">
            <div className="container" data-aos="fade-up">

              <div className="section-header">
                {/* <h2>HORECA</h2> */}
                <p>Ho<span>re</span>ca</p>
              </div>

              <ul className="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay="200">

                <li className="nav-item">
                  <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#menu-starters" onClick={()=>setIdHoreca(8)}>
                    <h4>Hotels</h4>
                  </a>
                </li>

                <li className="nav-item"  />
                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-breakfast" onClick={()=>setIdHoreca(4)}>
                  <h4>Restaurant</h4>
                </a>

                <li className="nav-item"  >
                  <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-lunch" onClick={()=>setIdHoreca(5)}>
                    <h4>Cafeteria</h4>
                  </a>
                </li>

                {/* <li className="nav-item">
            <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-dinner">
              <h4>Events</h4>
            </a>
          </li> */}

              </ul>

              <div className="tab-content" data-aos="fade-up" data-aos-delay="300">

                <div className="tab-pane fade active show" id="menu-starters">

                  <div className="tab-header text-center">
                    <p>Best hotels available for your vacation.</p>
                    {/* <h3>Best hotels available for your vacation.</h3> */}
                  </div>

                  <div className="row justify-content-center">

                
                  {horecaData?.homePoints?.slice(0,3).map((el)=>{
                    return(
                      <>
                               <article class="cardHotels text-white p-0 m-3" >
                      <img
                        class="card__background "
                        src={process.env.REACT_APP_UPLOADS + el?.url}
                        alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                        width="1920"
                        height="2193"
                      />
                      <div class="card__content | flow  w-100">
                        <div class="card__content--container | flow">
                          <h2 class="card__title">{el?.name}</h2>
                          <p class="card__description">
                            {el.description}
                          </p>
                        </div>
                        {/* <button class="card__button">Read more</button> */}
                      </div>
                    </article>
                      </>
                    )
                  })}
           

                  </div>
                </div>

                <div className="tab-pane fade" id="menu-breakfast">

                  <div className="tab-header text-center">
                    <p>Best restaurants available for you</p>
                    {/* <h3>Best restaurants available for you.</h3> */}
                  </div>

                  <div className="row justify-content-center">

                  {horecaData?.homePoints?.slice(0,3).map((el)=>{
                    return(
                      <>
                               <article class="cardHotels text-white p-0 m-3" >
                      <img
                        class="card__background "
                        src={process.env.REACT_APP_UPLOADS + el?.url}
                        alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                        width="1920"
                        height="2193"
                      />
                      <div class="card__content | flow  w-100">
                        <div class="card__content--container | flow">
                          <h2 class="card__title">{el.name}</h2>
                          <p class="card__description">
                            {el.description}
                          </p>
                        </div>
                        {/* <button class="card__button">Read more</button> */}
                      </div>
                    </article>
                      </>
                    )
                  })}

                  </div>
                </div>

                <div className="tab-pane fade" id="menu-lunch">

                  <div className="tab-header text-center">
                    <p>Best cafeteria available for you</p>
                    {/* <h3>Best cafeteria available for you</h3> */}
                  </div>

                  <div className="row justify-content-center">

                  {horecaData?.homePoints?.slice(0,3).map((el)=>{
                    return(
                      <>
                               <article class="cardHotels text-white p-0 m-3" >
                      <img
                        class="card__background "
                        src={process.env.REACT_APP_UPLOADS + el?.url}
                        alt="Photo of Cartagena's cathedral at the background and some colonial style houses"
                        width="1920"
                        height="2193"
                      />
                      <div class="card__content | flow  w-100">
                        <div class="card__content--container | flow">
                          <h2 class="card__title">{el.name}</h2>
                          <p class="card__description">
                            {el.description}
                          </p>
                        </div>
                        {/* <button class="card__button">Read more</button> */}
                      </div>
                    </article>
                      </>
                    )
                  })}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="team" className="chefs section-bg">
            <div class="container">
            <div className="section-header">
                {/* <h2>HORECA</h2> */}
                <p>Subscription <span>Plan</span></p>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-3 col-sm-6 m-4">
                  <div class="pricingTable">
                    <div class="pricingTable-header">
                      <i class="fa fa-adjust"></i>
                      <div class="price-value"> $10.00 <span class="month">per month</span> </div>
                    </div>
                    <h3 class="heading">Standard</h3>
                    <div class="pricing-content">
                      {/* <ul>
                        <li><b>50GB</b> Disk Space</li>
                        <li><b>50</b> Email Accounts</li>
                        <li><b>50GB</b> Monthly Bandwidth</li>
                        <li><b>10</b> subdomains</li>
                        <li><b>15</b> Domains</li>
                      </ul> */}
                            <ul>
                        <li><i class='fa fa-paper-plane'></i> Unlimited Email Addresses</li>
                        <li><i class="fa fa-cloud"></i> 50 GB Disk Space</li>
                        <li><i class="fa fa-database"></i> Unlimited MySQL Database</li>
                        <li class="not-avail"><i class="fa fa-clock"></i> 24X7 Support</li>
                        <li class="not-avail"><i class="fa fa-envelope"></i> Email Support</li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <a href="#">sign up</a>
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-sm-6 m-4">
                  <div class="pricingTable green">
                    <div class="pricingTable-header">
                      <i class="fa fa-briefcase"></i>
                      <div class="price-value"> $20.00 <span class="month">per month</span> </div>
                    </div>
                    <h3 class="heading">Business</h3>
                    <div class="pricing-content">
                      {/* <ul>
                            <li><b>60GB</b> Disk Space</li>
                            <li><b>60</b> Email Accounts</li>
                            <li><b>60GB</b> Monthly Bandwidth</li>
                            <li><b>15</b> subdomains</li>
                            <li><b>20</b> Domains</li>
                        </ul> */}
                      <ul>
                        <li><i class='fa fa-paper-plane'></i> Unlimited Email Addresses</li>
                        <li><i class="fa fa-cloud"></i> 50 GB Disk Space</li>
                        <li><i class="fa fa-database"></i> Unlimited MySQL Database</li>
                        <li class="not-avail"><i class="fa fa-clock"></i> 24X7 Support</li>
                        <li class="not-avail"><i class="fa fa-envelope"></i> Email Support</li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <a href="#">sign up</a>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 m-4">
                  <div class="pricingTable blue">
                    <div class="pricingTable-header">
                      <i class="fa fa-diamond"></i>
                      <div class="price-value"> $30.00 <span class="month">per month</span> </div>
                    </div>
                    <h3 class="heading">Premium</h3>
                    <div class="pricing-content">
                    <ul>
                        <li><i class='fa fa-paper-plane'></i> Unlimited Email Addresses</li>
                        <li><i class="fa fa-cloud"></i> 50 GB Disk Space</li>
                        <li><i class="fa fa-database"></i> Unlimited MySQL Database</li>
                        <li class="not-avail"><i class="fa fa-clock"></i> 24X7 Support</li>
                        <li class="not-avail"><i class="fa fa-envelope"></i> Email Support</li>
                      </ul>
                    </div>
                    <div class="pricingTable-signup">
                      <a href="#">sign up</a>
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-3 col-sm-6 m-4">
                <div class="pricingTable red">
                    <div class="pricingTable-header">
                        <i class="fa fa-cube"></i>
                        <div class="price-value"> $40.00 <span class="month">per month</span> </div>
                    </div>
                    <h3 class="heading">Extra</h3>
                    <div class="pricing-content">
                        <ul>
                            <li><b>80GB</b> Disk Space</li>
                            <li><b>80</b> Email Accounts</li>
                            <li><b>80GB</b> Monthly Bandwidth</li>
                            <li><b>25</b> subdomains</li>
                            <li><b>30</b> Domains</li>
                        </ul>
                    </div>
                    <div class="pricingTable-signup">
                        <a href="#">sign up</a>
                    </div>
                </div>
            </div> */}
              </div>
            </div>
          </section>
          <section id="contact" className="book-a-table">
            <div className="container" data-aos="fade-up">

              <div className="section-header">
                <h2>Contact</h2>
                <p>Need Help? <span>Contact Us</span></p>
              </div>

              <div className="row g-0">
                <div className="col-lg-5">
                  <iframe
                    style={{
                      border: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    src="https://maps.google.com/maps?q=cacttus&t=k&z=14&ie=UTF8&iwloc=&output=embed" frameborder="0" allowfullscreen=""></iframe>
                </div>

                <div className="col-lg-7 d-flex align-items-center reservation-form-bg">
                  <form className="contact-form" data-aos="fade-up" data-aos-delay="100">
                    <div className="row gy-4">
                      <div className="col-lg-4 col-md-6">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                        <div className="validate"></div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Email" data-rule="email" data-msg="Please enter a valid email" />
                        <div className="validate"></div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <input type="number" className="form-control" name="phone" id="phone" placeholder="Phone" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                        <div className="validate"></div>
                      </div>
                
                    </div>
                    <div className="form-group mt-3">
                      <textarea className="form-control" name="message" rows="5" placeholder="Message"></textarea>
                      <div className="validate"></div>
                    </div>
                    <div className="mb-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                    </div>
                    <div className="text-center"><button type="submit">Send</button></div>
                  </form>
                </div>

              </div>

            </div>
          </section>



        </main>


        <footer id="footer" className="footer">

          <div className="container">
            <div className="row gy-3">
              <div className="col-lg-3 col-md-6 d-flex">
                {/* <i className="fa fa-geo-alt icon"></i> */}
                <div>
                  <h4>Address</h4>
                  <p>
                  Rr. Bashkim Fehmiu<br />
                  Prishtinë, Republika e Kosovës<br />
                  </p>
                </div>

              </div>

              <div className="col-lg-3 col-md-6 footer-links d-flex">
                {/* <i className="fa fa-telephone icon"></i> */}
                <div>
                  <h4>Contacts</h4>
                  <p>
                    <strong>Phone:</strong> +1 1234 1234 55<br />
                    <strong>Email:</strong> info@example.com<br />
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 footer-links d-flex">
                {/* <i className="fa fa-clock icon"></i> */}
                {/* <div>
                  <h4>Opening Hours</h4>
                  <p>
                    <strong>Mon-Sat: 08:30</strong> - 17:00<br />
                    Sunday: Closed
                  </p>
                </div> */}
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4 >Follow Us</h4>
                <div className="social-links d-flex">
                  <a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
                  <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
                  <a href="#" className="instagram"><i className="fa fa-instagram"></i></a>
                  <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
                </div>
              </div>

            </div>
          </div>

          <div className="container">
            <div className="copyright">
              &copy; Copyright <strong><span>Ciceron</span></strong>
            </div>
            {/* <div className="credits">
      
        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div> */}
          </div>

        </footer>

        <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="fa fa-arrow-up"></i></a>

        {/* <div id="preloader"></div> */}
      </body>

    </>
  )
}

export default FrontPage;