import { SET_SELECTED_LANG, LOGMEIN, SET_USER_NAME, SET_SIDEBAR, SET_DATAFILTER,SET_USER_ID,SET_AUTH ,RM_AUTH} from "./types";

export const setSelectedLang = (data) => {
    return {
        type: SET_SELECTED_LANG,
        data: data
    }
}
export const logMeIn = (data) => {
    return {
        type: LOGMEIN,
        data: data
    }
}

export const setUserName = (data) => {
    return {
        type: SET_USER_NAME,
        data: data
    }
}
export const setSideBar = (data) => {
    return {
        type: SET_SIDEBAR,
        data: data
    }
}
export const setAuth = (auth) => {
    return {
        type: SET_AUTH,
        auth: auth
    };
};
export const rmAuth = () => {
    return { type: RM_AUTH, auth: null }
}
export const setUserID = (id, role) => {
    return {
        type: SET_USER_ID,
        user_id: id,
        user_role: role
    };
};
// export const setDataFilter = (data) => {
//     return {
//         type: SET_DATAFILTER,
//         data: data
//     }
// }
