import { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  useProSidebar,

} from "react-pro-sidebar";
import { Link,useLocation } from "react-router-dom";
import "./sidebar.scss";
import { connect } from "react-redux";
import { setSideBar } from "../../redux/Functions/actions";
import { MenuItems } from "../../data/sidebarData";
import harrisia_icon from "../frontPage/img/cWhiteIcon.svg";
import harrisia_logo from "../frontPage/img/Ciceron.png";
import { CgCloseR } from "react-icons/cg"
import { RiMenuLine } from "react-icons/ri";
import { FiLogOut } from "react-icons/fi"; // Import the logout icon
// import { SubMenuItem } from "../styles/MainLayoutResponsive";
import {
  setAuth,
  logMeIn, rmAuth
} from "../../redux/Functions/actions";

const SideBar = (props) => {
  const location = useLocation();
  console.log("locationlocation", location)
  const [icon, setIcon] = useState(props?.sidebarCollapse === "" ? false : true);
  const { collapseSidebar } = useProSidebar();
  const [viewportSize, setViewportSize] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setViewportSize(window.innerWidth);
  });

  function handleCloseCollapse() {
    props.setSideBar("collapsed");
    setIcon(false);
    collapseSidebar(true);
  }

  function handleOpenCollapse() {
    if (props.sidebar === "") {
      if (viewportSize < 768) {
        handleCloseCollapse();
      }
    } else {
      props.setSideBar("");
      setIcon(true);
      collapseSidebar(false);
    }
  }

  useEffect(() => {
    if (props.sidebar === "") {
      if (viewportSize < 768) {
        handleCloseCollapse();
      }
    } else {
      handleOpenCollapse();
    }
  }, []);

  const handleLogout = () => {
    props.logMeIn();
    props.rmAuth();
    window.localStorage.clear();
    window.location = "/login";
    // windeow
  };

  return (
    <div className={`sidebar_layout ${icon === true ? "" : "collapsed"}`}>
      <span
        className={`collapse_sidebar_button ${icon === true ? "" : "collapsed"}`}
        onClick={() => {
          props.sidebar === "collapsed"
            ? handleOpenCollapse()
            : handleCloseCollapse();
        }}
      >
        {icon === false ? (
           <RiMenuLine style={{ fontSize: "25px",color:"white"  }} role="button" />
          ) : (
            <CgCloseR style={{ fontSize: "30px",color:"white" }} role="button" />
        )}
      </span>
      <div
        className={
          props?.sidebarCollapse !== "collapsed" &&
            icon === false &&
            viewportSize < 800
            ? "d-none"
            : "sidebar-custom"
        }
      >
        <Sidebar collapsed>
          <Menu
            style={{
              display: "flex",
              backgroundColor: "#6ecde9",
              padding: "5px",
              position: "relative", // Added position: relative
            }}
            onClick={() => {
              props.sidebarCollapse === "collapsed" || icon === true
                ? handleOpenCollapse()
                : handleCloseCollapse();
            }}
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                if (level == 0) {
                  return {
                    color: disabled ? "black" : "black",
                    backgroundColor: active ? "#bdc8cf" : undefined,
                    borderRadius: active ? "12px" : undefined,
                  };
                }
              },
            }}
          >
            <MenuItem className="borderheader" disabled>
              <div className="logo_layout">
                {icon === true ? (
                  <img src={harrisia_logo} alt="ciceron. logo" className="termini-logo-text" />
                ) : (
                  <img src={harrisia_icon} alt="harrisia_icon" className="iconCollapsed" />
                )}
              </div>
            </MenuItem>
            <MenuItem style={{ marginLeft: "-15px" }} disabled className={icon === true ? "mt-5" : "mt-5 m-0"}>
              <strong className="text-secondary">General</strong>
            </MenuItem>
            {MenuItems?.map((el) => (
              <MenuItem
                style={{ marginRight: "5px" }}
                className={location.pathname === el?.path ? 'active1' : ''}
                component={<Link to={el?.path} />}
                onClick={handleOpenCollapse}
                key={el?.menu_name}
              >
                {icon === false ? (
                  <i className={el?.menu_icon} aria-hidden="true" />
                ) : (
                  <span>
                    <i className={el?.menu_icon} aria-hidden="true" />
                    <strong className="m-2">{el?.menu_name}</strong>
                  </span>
                )}
              </MenuItem>
            ))}
          </Menu>
        </Sidebar>
        <div className="logout-button" onClick={handleLogout}>
          <div className="btn btn-logout " >
            {icon === false ? (
              <FiLogOut style={{ marginRight: "8px" }} />
            ) : (
              <span>
                <FiLogOut style={{ marginRight: "8px" }} />
                <strong className="m-2">Logout</strong>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state.data.sidebar,
    auth: state.data.auth,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
    logMeIn: (data) => dispatch(logMeIn(data)),
    setAuth: (auth) => dispatch(setAuth(auth)),
    rmAuth: () => dispatch(rmAuth()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
