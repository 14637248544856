import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import {
    ModalStyle, ModalHandleButtons, ModalClose, ModalForm,
    ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons
} from "../styles/ModalStyle"
import { FaTimes, FaExclamationTriangle } from "react-icons/fa";
import ModalPortal from "../../portal/ModalPortal";
import { toast } from "react-toastify";

const deletePointModal = (props) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .delete("/point/?id=" + props?.pointData?.id)
            .then((res) => {
                toast.success("Point has been deleted successfully!");
                props?.getData();
                props?.handleModalClose();
                console.log(res);
            })
            .catch((error) => {
                toast.error("Something went wrong!");
                console.log(error);
            });
    };


    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle deletePoint>
                        <div className="text-center">
                            <FaExclamationTriangle className='display-3 text-danger mb-5' />
                            <h3>Delete Point</h3>
                            <h4 className='text-secondary'>You're going to delete the "{props?.pointData?.name}" Point.<br /> Are you sure?</h4>
                        </div>
                        <ModalButtonsPosition className='justify-content-between mt-5'>
                            <ModalMainButtons closeDeleteButton onClick={() => props?.handleModalClose()}>No, Keep it.</ModalMainButtons>
                            <ModalMainButtons type="submit" deleteModal onClick={(e) => handleSubmit(e)}>Yes, Delete! </ModalMainButtons>
                        </ModalButtonsPosition>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }
}

export default deletePointModal;