import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './events.scss';
import { TableStyleEvents } from "./eventsStyle.js"
import { setSideBar } from "../../redux/Functions/actions.js";
import { connect } from "react-redux";
import SearchInputHeader from "../Search/SearchInputHeader";
import ReactPaginate from 'react-paginate';
import { AddButton, TableStyle, PaginationStyle } from "../styles/MainLayout.js";
import axios from "../../axios.js"
import EventsModal from "./eventsModal.js";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { HiPlusSm } from "react-icons/hi";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import "./../../components/sidebar/sidebar.scss";
import { PointStyle } from "./../points/pointStyle.js";
import { Link } from "react-router-dom";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, 
  ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../styles/ModalStyle"
  import { FaTimes,FaExclamationTriangle } from "react-icons/fa";
import { toast } from "react-toastify";
import DeleteEventModal from "./deleteEventModal";
const Events = (props) => {
  const [viewportSize, setViewportSize] = useState(window.innerWidth);
  const [events, setEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [point, setPoint] = useState([]);
  console.log("props", props, viewportSize)

  //PAGINATION
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }

  const handleModalAdd = () => {
    setIsOpen(true);
    setModalType("add");
  };

  const handleModalEdit = (data) => {
    setIsOpen(true);
    setModalType("edit");
    setDataToModal(data);
  };

  // const handleModalDelete = (data) => {
  //   setIsOpen(true);
  //   setModalType("delete");
  // };
  const handleModalDelete = (data) => {
    setIsOpenDelete(true);
    setDeleteId(data);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  }

  const getData = () => {
    axios.get('events/all').then(
      data => {
        setEvents(data?.data);
        setFiltered(data?.data);
      }
    ).catch(err => { console.log('Errori', err) });
  }

  console.log("events",events)
  
  const getPointData = () => {
    axios.get('/point/all').then(
        data => {
            setPoint(data?.data)
            console.log("pointData",data?.data);
        }
    ).catch(err => { console.log('Errori', err) });
}
  
  useEffect(() => {
    getData();
    getPointData();
  }, []);
  
  return (
    <>
      {isOpen === true && <EventsModal getData={()=>getData()} type={modalType} data={dataToModal} isOpen={isOpen} handleModalClose={() => { setIsOpen(false); setDataToModal(null) }} user_id={props?.user_id} />}
      {isOpenDelete === true && <DeleteEventModal isOpen={isOpenDelete} getData={()=>getData()} handleModalClose={() => { setIsOpenDelete(false)}} user_id={props?.user_id} eventData={deleteId} />}
      <SearchInputHeader className={props?.sidebarCollapse !== "collapsed" && viewportSize < 600 ? "d-none" : "sidebar-custom"}  data={events} filtered={setFiltered} setPageNumber={setPageNumber}/>
      <PointStyle collapsedEvent={props?.sidebarCollapse} className={props?.sidebarCollapse !== "collapsed" ? "notCollapsedSideBar text-left" : "collapsedSideBar  text-left"}>
        <TableStyleEvents collapsedEvent={props?.sidebarCollapse} className="table-responsive">
          <Table className="custom-table table table-hover">
            <Thead>
              <Tr>
                <Th className="table-header">Event Name</Th>
                <Th className="table-header">Point Name</Th>
                <Th className="table-header">Description</Th>
                <Th className="table-header">Start Time</Th>
                <Th className="table-header">End Time</Th>
                <Th className="table-header">Edit</Th>
                <Th className="table-header">Event Data</Th>
                <Th className="table-header">Delete</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka evente</h6></div>}
              {filtered && filtered?.length > 0 &&
                <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => (
                    <Tr key={index}>
                      {console.log("first1",point?.filter((element)=>(element?.id==el?.point_id))[0]?.name)}
                      <Td>{el.name}</Td>
                      <Td>{point?.filter((element)=>(element?.id==el?.point_id))[0]?.name}</Td>
                      {/* <Td>{el.description}</Td> */}
                      <Td>{el?.description?.length > 30 ? el?.description?.slice(0, 30) + '...' : el?.description}</Td>
                      <Td>{el.start_time?.slice(0, 10) + "  " + el.start_time?.slice(11, 16)}</Td>
                      <Td>{el.end_time?.slice(0, 10) + "  " + el.end_time?.slice(11, 16)}</Td>
                      <Td><FaEdit onClick={() => handleModalEdit(el)} /></Td>
                      <Td className="text-center"><Link className="text-dark"
                          to={'/edit-event/' + el?.id}>
                          <FaEdit />
                        </Link></Td>
                      <Td><RiDeleteBin6Fill type="button" onClick={() => handleModalDelete(el)}/></Td>
                    </Tr>
                  ))}
                </>
              }
            </Tbody>
          </Table>
        </TableStyleEvents>
        <PaginationStyle collapse={props?.sidebarCollapse}>
          <ReactPaginate
            previousLabel={<MdKeyboardDoubleArrowLeft />}
            nextLabel={<MdKeyboardDoubleArrowRight />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
            currentPage={events?.length > productsPerPage ? true : false}
          />
        </PaginationStyle>
        <AddButton collapse={props?.sidebarCollapse} className={props?.sidebarCollapse !== "collapsed" ? "notCollapsed" : "addButtonNotCollapsed"}>
          <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5" onClick={() => handleModalAdd()}></i>
        </AddButton>

      </PointStyle>
   
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
