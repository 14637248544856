import React from "react";
import {
  SearchInputBox,
  SearchInput,
  IconsHeader,
  IconHeader,
  InputIcone,
  IconeI,
  SearchIcones
} from "../styles/MainLayout";
import { BsSun } from "react-icons/bs";
// import { ImExit } from "react-icons/im";
import { CiSearch } from "react-icons/ci";
import {  setSideBar } from "../../redux/Functions/actions";
import { connect } from "react-redux";
// import HeaderIcons from "./headerIcons";
const SearchInputHeader = (props) => {
  console.log("search123",props)
  let {data, filtered, setPageNumber} = props

  const searcher = async (text) => {
      let searchText = await text?.target?.value.toLowerCase();
      let filteredUser = data?.filter((dataCallBack) => {
          console.log('users incoming', dataCallBack?.name)
          if(dataCallBack?.name) {
              return (dataCallBack?.name?.toLowerCase()?.includes(searchText))
              // setPageNumber(0);
         
          } else if(dataCallBack?.category_name) {
              return (dataCallBack?.category_name?.toLowerCase()?.includes(searchText))
          } 
          else  {
              return (dataCallBack?.user_name?.toLowerCase()?.includes(searchText))
              // setPageNumber(0);
          }
      })
      filtered(filteredUser)
      setPageNumber(0)
  }
  return (
    <>
      <SearchInputBox collapse={props?.sidebar} 
      className={props?.sidebar !== "collapsed"? "notCollapsed":""}>
        <InputIcone >
          <SearchInput  className="form-control" placeholder="Search" type="search" onChange={searcher} />
        </InputIcone>
      </SearchInputBox>
       {/* <HeaderIcons/> */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebar: state?.data?.sidebar,
    dataFilter : state?.data?.dataFilter
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchInputHeader);
// export default SearchInputHeader;


