import React, { useEffect, useState } from "react";
import axios from "../../axios";

import "./points.scss";

import Modal from "react-modal";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";


import { useParams } from "react-router-dom";
// Make sure to bind modal to your appElement (https://reactcommunity.org/../../portal/ModalPortal/accessibility/)
Modal.setAppElement("#root");

const DeletePoint = (props) => {
  const [userdata, adduserdata] = useState([]);
  const [userroles, adduserroles] = useState([]);
  const [languages, addlanguages] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [mapValidation, setMapValidation] = useState("");
  const [mapData, setMapData] = useState([]);
  const { idParams } = useParams();
  const [imageData, setImageData] = useState(null);

  const [mapState, setMapState] = useState([0, 0]);
 
  const [center, setCenter] = useState([0, 0]);
  const [located, setLocated] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [countries, setCountries] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [imgError, setImgError] = useState("");
  const [audio, setAudio] = useState(null);
  const [image, setImage] = useState(null);
  // const [cId, setCID] = useState('');

  const [singleData, setSingleData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [descriptionData, setDescriptionData] = useState([]);


  const [imagee, setImagee] = useState();
  let subtitle;
  let cId = "";


  const idd = idParams;
 
  console.log("IDDDD",idd)

  // let id = props?.match.params.id;

  let map;

  const latLongFunc = async (e) => {
    const ud = Object.assign({}, userdata);
    ud.latitude = e[0];
    ud.longitude = e[1];
    await adduserdata(ud);
  };
 
  // console.log('map data yyyyyyyyyyy', mapData)

  const latitude = (e) => {
    const ud = Object.assign({}, mapData);
    ud.latitude = e.target.value;
    setMapData(ud);
  };
  const longitude = (e) => {
    const ud = Object.assign({}, mapData);
    ud.longitude = e.target.value;
    setMapData(ud);
  };

  // console.log('leaflet map')

  const getPoint = async () => {
    try {
      let response = await axios.get(`/point/id?id=${idd}`);
      adduserdata(response?.data);
    } catch (error) {
      // toast.error('Something went wrong!')
      console.log(error);
    }
  };

  useEffect(() => {
    getImageData();
    getDescriptionData();
    getAudioData();
    getCities();
    getCategories();
    getSubCategories();
    getPoint();
    getCities();
    getCategories();
    getSubCategories();
    getAllCountries();
    axios
      .get("languages/all")
      .then((data) => {
        addlanguages(data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, [idd, singleData]);

  // const defValues = () => {
  //     const ud = Object.assign({}, userdata);
  //     ud.auth = 'initial-auth';
  //     ud.mobileauth = 'initial-auth';
  //     adduserdata(ud);
  // }

  const pointName = (e) => {
    const ud = Object.assign({}, userdata);
    ud.name = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const def_lang = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.def_lang = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const country = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.country_id = e.target.value;
    console.log("ZZZZZZZZZZZZZZZZZZ", ud.country);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const city = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.city_id = e.target.value;

    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const category = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.category_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const subCategory = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.subcategory_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const active = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.active = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const langID = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.lang_id = e.target.value;
    adduserdata(ud);
    console.log("seeme", userdata);
  };
  // const buttonState = () => {
  //     setDisableButton(!disableButton);
  // }

  // console.log('heheh', disableButton);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .delete("/point/?id="+idd)
      .then((res) => {
        toast.success("Point has been deleted successfully!");
        console.log(res);
        window.history.back();
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        console.log(error);
      });
  };


  const getCategories = async () => {
    try {
      let response = await axios.get("point-categories/all");
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCities = async () => {
    try {
      let response = await axios.get("cities/all");
      setCities(response.data);
    } catch (error) {
      console.log(error);
    }
  };



  const getAllCountries = async () => {
    try {
      let response = await axios.get("countries/all");
      setCountries(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSubCategories = async () => {
    try {
      let response = await axios.get("point-subcategories/all");
      setSubCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAudioData = async () => {
    try {
      let response = await axios.get(`point-data/audio/${idd}`);
      console.log("", response?.data);
      setAudioData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDescriptionData = async () => {
    try {
      let response = await axios.get(`point-data/description/${idd}`);
      setDescriptionData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getImageData = async () => {
    try {
      let response = await axios.get(`point-data/image/${idd}`);
      setImageData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleContent = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [singleAudio, setSingleAudio] = useState([]);

  const getSingleAudio = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleImage = async (id) => {
    try {
      let response = await axios.get(`point-data/single/${id}`);
      console.log("response...................", response.data);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div className="points p-5 text-left">
      <div className="row">
        <h1>Delete Point</h1>
      </div>
      {/*<button onClick={buttonState} type="btn btn-success button">Add Points</button>*/}
      <div className={"myMap"}>
        <div>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
          {/*<button onClick={openModal} className={'map-button showme'}><i*/}
          {/*    className="fas fa-map-marker-alt"></i><span className={'hideme map-button-txt'}>Add Point Location</span>*/}
          {/*</button>*/}
          <div className="row mb-5">
            {/* <div className="col-md-12">
              <MapContainer center={center} zoom={13} touchZoom={false} dragging={false} scrollWheelZoom={false}   >
                <MyComponent />
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {userdata != "" ? (
                  <Marker  position={[userdata.latitude, userdata.longitude]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                ) : mapState != "" ? (
                  <Marker position={[mapState[0], mapState[1]]}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                ) : (
                  ""
                )}
              </MapContainer>
            </div> */}
          </div>
        </div>
      </div>
      {!isloading ? (
        ""
      ) : (
        <>
        <div className="row buttons-group">
          <div className="col-md-4">
          
            <h2>Content</h2>
            <div className="row">
              <div className="col-md-12">
                {descriptionData?.map((el, i) => {
                  return (
                    <div className="listimi showme">
                      <div className="row">
                        <div className="col-7">{el?.content}</div>
                        <div className="col-2">{el?.lang}</div>
                      
                    
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-4">
           
            <h2>Audio</h2>
            <div className="row">
              <div className="col-md-12">
                {audioData?.map((el, i) => {
                  // console.log('hehe audio', el)
                  return (
                    <div className="listimi showme">
                      <div className="row">
                        <div className="col-3">{el?.data_type_name}</div>
                        <div className="col-3">{el?.data_type}</div>
                        <div className="col-3">{el?.lang}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-md-4">
          
            <h2>Image</h2>
            <div className="row">
              {imageData?.map((el, i) => {
                // console.log('audio audio audio', process.env.REACT_APP_UPLOADS + el.url)
                return (
                  <div className="col-md-4 showme">
                    <img
                      src={process.env.REACT_APP_UPLOADS + el?.url}
                      className="card-img-top image-part"
                      alt="..."
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <form className='fromAdd' onSubmit={handleSubmit}>
          <div className={"row p-3"}>
            {"Lat: " + userdata.latitude + "  Long: " + userdata.longitude}
          </div>
          <input
          disabled
            type="text"
            required
            title="This field is required"
            placeholder="Point name"
            defaultValue={userdata?.name}
            onChange={pointName}
          ></input>
          <select disabled onChange={category} defaultValue={userdata?.category_id}>
            <option disabled defaultValue={userdata?.category_id}  selected>
              Select Category
            </option>
            {categories?.map((el) => {
              return <option  value={el.id}>{el.name}</option>;
            })}
          </select>
          <select
            onChange={subCategory}
            defaultValue={userdata?.subcategory_id}
            disabled
          >
            <option disabled defaultValue={userdata?.subcategory_id} selected>
              Select Sub-Category
            </option>
            {subCategories?.map((el) => {
              return <option value={Number(el.id)}>{el.name}</option>;
            })}
          </select>
          <select disabled onChange={city} defaultValue={userdata?.city_id}>
            <option disabled defaultValue={userdata?.city_id} selected>
              Select City
            </option>
            {cities?.map((el) => {
              return <option value={Number(el.id)}>{el.name}</option>;
            })}
          </select>
          <select disabled onChange={country} defaultValue={userdata?.country_id}>
            <option disabled selected>
              Select Country
            </option>
            {countries?.map((el) => {
              return <option value={Number(el.id)}>{el.name}</option>;
            })}
          </select>
          {/*<select onChange={def_lang}>*/}
          {/*    <option disabled selected>Select Language</option>*/}
          {/*    {languages?.map((el) => {*/}
          {/*        return (<option value={Number(el.id)}>{el.name}</option>)*/}
          {/*    })}*/}
          {/*</select>*/}
          <select disabled defaultValue={userdata?.active ? 1 : 0} onChange={active}>
            <option>Select Status</option>
            <option value={0}>Passive</option>
            <option value={1}>Active</option>
          </select>
          <button type="submit" className="w-100 edit-point-button">
            Delete Point
          </button>
        </form>
        </>

      )}

      <div>
        
      </div>
        
    </div>
  );
};
export default DeletePoint;
