import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import '../events/eventsStyle';
import '../events/events.scss';
import { TableStyleEvents } from "../events/eventsStyle.js"
import { setSideBar } from "../../redux/Functions/actions.js";
import { connect } from "react-redux";
// import { TableStyleEvents } from "../../styles/MainLayout"
import SearchInputHeader from "../Search/SearchInputHeader.js";
import ReactPaginate from 'react-paginate';
import { AddButton, TableStyle, PaginationStyle } from "../styles/MainLayout.js";
import axios from "../../axios.js"
// import EventsModal from "./restaurantModal.js";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { HiPlusSm } from "react-icons/hi";
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import "./../../components/sidebar/sidebar.scss";
import { Link } from "react-router-dom";
import { PointStyle } from "./pointStyle";
import Add from "./add";
import DeletePointModal from "./deletePointModal";
// import { AddButton, TableStyle } from "../../components/styles/MainLayout.js"
const Point = (props) => {
  const [viewportSize, setViewportSize] = useState(window.innerWidth);
  const [events, setEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [dataToModal, setDataToModal] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [deleteID, setDeleteID] = useState();
  console.log("events", events)

  //PAGINATION
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }
  const handleModalAdd = () => {
    setIsOpen(true);
    setModalType("add");
  };

  const handleModalEdit = (data) => {
    setIsOpen(true);
    setModalType("edit");
    setDataToModal(data);
  };

  const handleModalDelete = (data) => {
    // setIsOpen(true);
    setIsOpenDelete(true);
    setDeleteID(data);
    // setModalType("delete");
  };

  const handleModalClose = () => {
    setIsOpen(false);
  }

  const getData = () => {
    axios.get('/point/all').then(
      data => {
        setEvents(data?.data);
        setFiltered(data?.data);
      }
    ).catch(err => { console.log('Errori', err) });
  }


  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {isOpen === true && <Add type={modalType} eventLength={events?.length} data={dataToModal} isOpen={isOpen} getData={() => getData()} handleModalClose={() => { setIsOpen(false); setDataToModal(null) }} user_id={props?.user_id} />}
      {isOpenDelete === true && <DeletePointModal isOpen={isOpenDelete} getData={() => getData()} handleModalClose={() => { setIsOpenDelete(false) }} user_id={props?.user_id} pointData={deleteID} />}
      <SearchInputHeader className={props?.sidebarCollapse !== "collapsed" && viewportSize < 800 ? "d-none" : "sidebar-custom"} data={events} filtered={setFiltered} setPageNumber={setPageNumber} />
      <PointStyle collapsedEvent={props?.sidebarCollapse} className={props?.sidebarCollapse !== "collapsed" ? "notCollapsedSideBar text-left mt-2" : "collapsedSideBar text-left "}>
        <TableStyleEvents collapsedEvent={props?.sidebarCollapse} className="table-responsive"  >
          <Table className="custom-table table table-hover" >
            <Thead>
              <Tr>
                <Th className="table-header">Restaurant Name</Th>
                <Th className="table-header">City</Th>
                <Th className="table-header">Country</Th>
                <Th className="table-header">Subcategory Name</Th>
                <Th className="table-header">Status</Th>
                <Th className="table-header">Edit</Th>
                {/* <Th className="table-header">Delete</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>No data</h6></div>}
              {filtered && filtered?.length > 0 && <>
                {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                  return (
                    <>
                      <Tr>
                        <Td>{el?.name}</Td>
                        <Td>{el?.city_name}</Td>
                        <Td>{el?.country_name}</Td>
                        <Td>{el?.subcategory_name}</Td>
                        <Td>{el?.active ? "Approved": "Pending"}</Td>
                        {el?.active ? 
                        <Td> <Link className="text-dark"
                        to={'/edit-point/' + el?.id}>
                          <FaEdit />
                        </Link></Td>
                        : <Td></Td>}
                        {/* <Td> <Link className="text-dark"
                          onClick={() => handleModalDelete(el)}><RiDeleteBin6Fill />
                        </Link></Td> */}
                        {/* <Td><RiDeleteBin6Fill onClick={() => handleModalDelete()} /></Td> */}
                      </Tr>
                    </>
                  )
                })}</>
              }
            </Tbody>
          </Table>
        </TableStyleEvents>
        <PaginationStyle collapse={props?.sidebarCollapse}>
          <ReactPaginate
            previousLabel={<MdKeyboardDoubleArrowLeft />}
            nextLabel={<MdKeyboardDoubleArrowRight />}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"pagination justify-content-center"}
            activeClassName={"active"}
            currentPage={events?.length > productsPerPage ? true : false}
          />

        </PaginationStyle>
        <AddButton collapse={props?.sidebarCollapse}
          className={props?.sidebarCollapse !== "collapsed" ? "notCollapsed" : ""} >
            {events?.length != 0 
            ? "" 
            : <i type={"button"} className="fa-solid fa-circle-plus fa-2xl pt-5" onClick={() => handleModalAdd()}></i>
            }
        </AddButton>
      </PointStyle>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Point);
