import styled, { css } from "styled-components";

export const TableStyleEvents = styled.div`
  border-bottom: 3px solid rgb(110 205 233 / 45%);
  border-top: 3px solid rgb(110 205 233 / 45%);
  box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  -webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  border-radius: 10px;
  background-color: white;

  width: 100%;
  z-index: -1;
   /* ${(props) =>
    props?.collapsedEvent
      ? css`
    `
      : css`
       width: 95%;
    margin-left: unset;
    `} */
    /* display: flex; */
  justify-content: center;
  overflow-x: auto;
  ${(props) =>
    props.tableEditPoint && css`
    margin-left: unset;
    width: unset;

    `}
  ${(props) =>
    props.collapsedEvent
      ? css`
          /* margin-left: 250px; */
   
        `
      : css`
  max-width: calc(100% + 250px);

                   @media screen and (max-width: 600px) {
    margin-left: 0;
  }

  @media screen and (min-width: 601px) and (max-width: 900px) {
    margin-left: 0;
  }

  @media screen and (min-width: 901px) and (max-width: 1200px) {
    margin-left: 250px;
    max-width: calc(100% - 250px);
    ${(props) =>
          props.tableEditPoint && css`
    margin-left: unset;
    width: unset;
    max-width: unset;

    `}
  }

  @media screen and (min-width: 1201px) {
    margin-left: 250px;
    max-width: calc(100% - 250px);
    ${(props) =>
          props.tableEditPoint && css`
    margin-left: unset;
    width: unset;
    max-width: unset;

    `}
  }
        `}



    
    .custom-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  
    thead {
      tr {
        th {
          padding: 16px;
          background: rgb(189 200 207 / 65%);
        }
      }
    }
  
    tbody {
    tr {
      td {
        background-color: #f9f9f9;
        border-top: 1px solid #ccc;
        padding: 10px 17px;
        word-break: break-word;
        .tdBefore{
          z-index: 2;
          width: 97%;
        }
          .image-part {
    margin-bottom: 5px;
    width: 160px;
    height: auto;
  }

        svg{
        cursor: pointer;
        }
      }
      &:last-child {
        td {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        }
      }
    }
    }
  
  @media screen and (max-width: 650px) {
    tbody {
      tr {
        padding: 1.25em;
        border:none;
        td {
        display: block;
        width: 100%;
        }
      }
    }
    }
  }
`