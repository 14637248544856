import styled, { css } from "styled-components";


//TABLES


export const TableStyle = styled.div`
  border-top: 5px solid #6ECDE9;
  box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  -webkit-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -2px 4px 19px -2px rgba(0,0,0,0.75);
  border-radius: 10px;
  background-color: white;
  position: fixed;
  top: 15%;
  left: 8%;
  width: 90%;
  z-index: -1;
  transition: all 300ms ease-in-out;
  
  ${(props) =>
    props?.collapse? css`
      left:5%`
    : css`
      left: 18%;
      width: 80%;`
  }
  table {
    border-collapse: collapse;
    width: 100%;
    border-spacing: 10px 15px !important;
  }
  tr:nth-child(even) {
    background-color:#e1e5ee;
  }
  th,
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 40px;
  }
`;



export const AddButton = styled.div`
  top: unset;
  bottom: 3%;
  right: 1%;
  position: fixed;
  font-size: 20px;
  button{
background-color: #6ECDE9;
  }
`;

export const UsersForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormControl = styled.div`
  padding: 5%;
  border-radius: 10px;
`;
export const Label = styled.label`
width: 100%;
    font-size: 15px;
    background-color: white;

`;
export const Input = styled.input.attrs(props => ({
  // type: "text",
  // placeholder:"Your fullname...",


}))`

  width: 100%;
  border: none;
  padding: 1%;
  margin-bottom: 2%;
  border-bottom: 2px solid #BDE9F5;
  background-color:white;
`;



export const SearchInputBox = styled.div`
    display: flex;
    justify-content: end;
    background-color: #6ecde9;
    height: 64px;
    width: 100%;
    top: 0px;
    z-index: 2;
    @media only screen and (max-width: 600px) {
      justify-content: start;
      position: fixed;
      margin-right: 9%;
      &.notCollapsed{
       display: none;
      }
  }

`
export const SearchInput = styled.input`
    border: 1px solid #6ECDE9;
    border-radius: 11px;
    /* margin-bottom: 1%; */
    padding: 5px 22px;
    margin-top: 18px;
    z-index: 9;
    width: 96%;
    color: rgb(211, 202, 202);
    @media only screen and (max-width: 600px) {
      width: 100%;
    margin-left: 10px;
  }

`
export const IconsHeader = styled.div`
    margin-top: 1.6%;
     margin-bottom: 1%;
     margin-left: 71%;
     margin-right: 20px;
    display: flex;
    gap: 20px;

    
`

export const InputIcone = styled.div`
  /* position: fixed; */
  /* margin-left: 202px; */
  transition: all 300ms ease-in-out;
  ${(props) =>
    props?.collapse
      ? css`
  background-color: #0C0B09;
  position: fixed;
  left: 0;
  right: 42px;
  top: 0px;
  `
      :
      ``
  }

`
export const IconeI = styled.div`
 position: absolute;
  z-index:99;
  margin-top: 22px;
  margin-left: 10px;

`
export const PaginationStyle = styled.div`
  transition: all 300ms ease-in-out;
  margin-top: 20px;
`

export const MainContent = styled.div`
  ${(props) =>
    props.collapsedEvent
      ? css`
          /* margin-left: 250px; */
   
        `
      : css`
  max-width: calc(100% + 250px);

                   @media screen and (max-width: 600px) {
    margin-left: 0;
  }

  @media screen and (min-width: 601px) and (max-width: 900px) {
    margin-left: 0;
  }

  @media screen and (min-width: 901px) and (max-width: 1200px) {
    margin-left: 250px;
    max-width: calc(100% - 250px);
  }

  @media screen and (min-width: 1201px) {
    margin-left: 250px;
    max-width: calc(100% - 250px);
  }
        `}

`;