import React, { useEffect, useState } from "react";
import axios from '../../axios';
import ModalPortal from "../../portal/ModalPortal";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../styles/ModalStyle"
import { FaTimes } from "react-icons/fa";
import { TimePicker } from "antd";
import 'antd/dist/reset.css'
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";

const EventsModal = (props) => {
    console.log("ppp", props)
    const [isEdit, setEdit] = useState(false);
    const [isdelete, setDelete] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [viewportSize, setViewportSize] = useState(window.innerWidth);
    const [point, setPoint] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [defaultImageUrl, setDefaultImageUrl] = useState(process.env.REACT_APP_UPLOADS + props?.data?.image_url);
    const [selectedImage, setSelectedImage] = useState(null);
    const formattedDefaultStartTime = props?.data?.start_time ? props?.data?.start_time.slice(0, 16) : '';
    const formattedDefaultEndTime = props?.data?.end_time ? props?.data?.end_time.slice(0, 16) : '';
    const [submitting, setSubmitting] = useState(false);

    const handleEdit = (isEdit) => {
        if (isEdit) {
            setEdit(false);
            setIsAdd(false);
            setDelete(false);
        } else {
            setEdit(true);
            setIsAdd(false);
            setDelete(false);
        }
    }
    document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            props?.handleModalClose()
        }
    })

    const name = (e) => {
        const ud = { ...eventData };
        ud.name = e.target.value;
        setEventData(ud);
    };
    const description = (e) => {
        const ud = { ...eventData };
        ud.description = e.target.value;
        setEventData(ud);
    };

    const start_time = (e) => {
        const ud = { ...eventData };
        const selectedDate = new Date(e.target.value);
        selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
        ud.start_time = selectedDate.toISOString();
        setEventData(ud);
    };


    const end_time = (e) => {
        const ud = { ...eventData };
        const selectedDate = new Date(e.target.value);
        selectedDate.setMinutes(selectedDate.getMinutes() - selectedDate.getTimezoneOffset());
        ud.end_time = selectedDate.toISOString();
        setEventData(ud);
    };

    const point_id = (e) => {
        const ud = { ...eventData };
        ud.point_id = Number(e.target.value);
        setEventData(ud);
    };

    const external_url = (e) => {
        const ud = { ...eventData };
        ud.external_url = e.target.value;
        setEventData(ud);
    };

    const image_url = (e) => {
        const ud = { ...eventData };
        ud.image_url = e.target.files[0];
        setEventData(ud);
    };
    useEffect(() => {
        const ud = Object.assign({}, eventData);
        ud.user_id = props?.user_id;
        ud.point_id = props?.data?.point_id;
        ud.city_id = props?.data?.city_id;
        ud.description = props?.data?.description;
        ud.external_url = props?.data?.external_url;
        // ud.image_url = props?.data?.image_url;
        ud.start_time = props?.data?.start_time;
        ud.end_time = props?.data?.end_time;
        ud.name = props?.data?.name;
        setEventData(ud);
    }, [props?.isOpen]);

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        if (!submitting) {
            setSubmitting(true);
            let fd = new FormData();
            fd.append("user_id", props?.user_id);
            fd.append("point_id", eventData?.point_id);
            fd.append("description", eventData?.description);
            fd.append("external_url", eventData?.external_url);
            fd.append("image_url", eventData?.image_url);
            fd.append("start_time", eventData?.start_time);
            fd.append("end_time", eventData?.end_time);
            fd.append("name", eventData?.name);
            fd.append("city_id", point?.find(el=> el?.id === eventData?.point_id)?.city_id);
            console.log("fbbb", fd)
            axios
                .post(`events`, fd)
                .then((data) => {
                    props?.handleModalClose();
                    toast.success("Eventi u shtua me sukses!");
                    props?.getData();
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("Dicka shkoi gabim!");
                }).finally(() => {
                    setSubmitting(false);
                })
        }
    };
    

    const handleEditEvent = async (e) => {

        e.preventDefault();
        let fd = new FormData();
        fd.append("id", props?.data?.id);
        fd.append("user_id", props?.user_id);
        fd.append("point_id", eventData?.point_id);
        fd.append("description", eventData?.description);
        fd.append("external_url", eventData?.external_url);
        fd.append("image_url", eventData?.image_url);
        fd.append("start_time", eventData?.start_time);
        fd.append("end_time", eventData?.end_time);
        fd.append("name", eventData?.name);
        fd.append("city_id", point?.find(el=> el?.id === eventData?.point_id)?.city_id);
        console.log("fbbb", fd)
        axios
            .put(`events`, fd)
            .then((data) => {
                props?.handleModalClose();
                toast.success("Eventi u ndryshua me sukses!");
                props?.getData();
            })
            .catch((err) => {
                console.log(err);
                toast.error("Dicka shkoi gabim!");
            });
    };


    const getPointData = () => {
        axios.get('/point/all').then(
            data => {
                setPoint(data?.data)
            }
        ).catch(err => { console.log('Errori', err) });
    }

    window.addEventListener("resize", () => {
        setViewportSize(window.innerWidth);
    });

    useEffect(() => {
        getPointData();
    }, [props?.isOpen]);

    // console.log("CITYAJDI", point?.filter((el)=>{el.id===}))

    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle>
                        <ModalButtonsPosition>
                            {props?.type !== "add" ? (
                                <>
                                    <ModalHandleButtons edit onClick={() => handleEdit(isEdit)} >
                                        {isEdit ? <FaTimes role="button" /> : <FaEdit role="button" />}
                                    </ModalHandleButtons>

                                    {!isEdit &&
                                        <ModalHandleButtons close onClick={() => props?.handleModalClose()} >
                                            <FaTimes role="button" />
                                        </ModalHandleButtons>}

                                </>
                            ) : (
                                <ModalHandleButtons
                                    close
                                    onClick={() => props?.handleModalClose()}
                                ><FaTimes role="button" />
                                </ModalHandleButtons>
                            )}
                        </ModalButtonsPosition>
                        <ModalForm onSubmit={
                            props?.type === 'add' ? handleSubmit : handleEditEvent}
                        >
                            <h4 className="text-center">{props?.type !== 'add' ? "Edit Event" : "Add Event"}</h4>
                            <div className="mb-3 mt-5">
                                <span className="text-dark">Pika</span>
                                <select
                                    className="form-control"
                                    disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                    defaultValue={props?.data?.point_id}
                                    title="This field is required" onChange={point_id} required="required">
                                    <option value="" disabled selected>Select point</option>
                                    {point?.filter(item => item.category_id === 2)?.map((el) => {
                                        return <option value={el?.id} id={el?.id} selected={Number(props?.data?.point_id) == el?.id ? "selected" : false}>{el?.name}</option>
                                    })}
                                </select>

                                <div className="row mt-3">
                                    <div className="col-lg-6 col-md-3 col-12 text-dark">
                                        <span className="text-dark">Start time</span>
                                        <input type="datetime-local" className="form-control" onChange={start_time}
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            defaultValue={formattedDefaultStartTime} required />
                                    </div>
                                    <div className="col-lg-6 col-md-3 col-12 text-dark">
                                        <span className="text-dark">End time</span>
                                        <input type="datetime-local" className="form-control" onChange={end_time}
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            defaultValue={formattedDefaultEndTime} required
                                        //  defaultValue={props?.data?.end_time} 
                                        />
                                    </div>
                                </div>

                            </div>
                            <div class="row mb-1">
                                <div class="form-outline">
                                    <span class="form-label" for="firstName">Event name</span>
                                    <input type="text" class="form-control" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={name} defaultValue={props?.data?.name} required />
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="form-outline">
                                    <span class="form-label" for="firstName">Description</span>
                                    <textarea type="text" class="form-control" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={description} defaultValue={props?.data?.description} required />
                                </div>
                            </div>

                            <div class="row mb-1">
                                <div class="col-md-6 mb-4 pb-2">
                                    <div class="form-outline">
                                        <span class="form-label" for="externalUrl">External url</span>
                                        <input type="text" id="externalUrl" class="form-control" disabled={(props?.type !== 'add' && isEdit === false) ? true : false} onChange={external_url} defaultValue={props?.data?.external_url} />
                                    </div>
                                </div>

                                <div class="col-md-6 mb-4 pb-2">
                                    <div class="form-outline">
                                        <span class="form-label" for="imageUrl">Image url</span>

                                        {props?.type !== 'add' ?
                                            <img className="eventImage" src={selectedImage != null ? URL.createObjectURL(selectedImage) : process.env.REACT_APP_UPLOADS + props?.data?.image_url} alt="Preview" />
                                            : ""}
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="inputGroupFile"
                                            name="image"
                                            disabled={(props?.type !== 'add' && isEdit === false) ? true : false}
                                            aria-describedby="inputGroupFileAddon03"
                                            aria-label="Upload"
                                            onChange={image_url}
                                        />
                                    </div>
                                </div>
                            </div>
                            {isEdit == false && props?.type != "add" ? "" :
                                <ModalButtonsPosition>
                                    
                                    <ModalMainButtons type="submit"  disabled={submitting}>{props?.type === 'add' ? "Shto" : "Edit"}</ModalMainButtons>
                                </ModalButtonsPosition>
                            }
                        </ModalForm>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }

}
export default EventsModal