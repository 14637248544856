import React, { useEffect, useState } from "react";
import axios from "../../axios";
import { setSideBar } from "../../redux/Functions/actions.js";
import { connect } from "react-redux";
// import "./points.scss";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap
} from "react-leaflet";
import { PointStyle } from "./pointStyle";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaExclamationTriangle } from "react-icons/fa";

import { TableStyleEvents } from "../events/eventsStyle"
import { MainContent } from "./../styles/MainLayout"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
// import closeIcn from "../../Images/close.svg";
import { ModalStyle, ModalHandleButtons, ModalClose, ModalForm, ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons } from "../styles/ModalStyle"
import { AddButton, TableStyle, PaginationStyle } from "../styles/MainLayout.js";


import { FaTimes } from "react-icons/fa";
// Make sure to bind modal to your appElement (https://reactcommunity.org/../../portal/ModalPortal/accessibility/)
// Modal.setAppElement("#root");

const EditEvent = (props) => {
  // alert("Test")
  const [userdata, adduserdata] = useState([]);
  const [languages, addlanguages] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [mapValidation, setMapValidation] = useState("");
  const [mapData, setMapData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [mapState, setMapState] = useState([0, 0]);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const [modalIsOpen5, setIsOpen5] = React.useState(false);
  const [modalIsOpenDelete, setModalIsOpenDelete] = React.useState(false);
  const [modalIsOpenAudio6, setIsOpenAudio6] = React.useState(false);
  const [modalIsOpenImage7, setIsOpenImage7] = React.useState(false);
  const [validationError, setValidationError] = useState("");
  const [countries, setCountries] = useState([]);
  const [imgError, setImgError] = useState("");
  const [audio, setAudio] = useState(null);
  const [image, setImage] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [descriptionData, setDescriptionData] = useState([]);
  const [isDisabled, setDisabled] = useState(false);
  const [successDeletedMsg, setSuccessDeletedMsg] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [descriptonPutID, setDescriptionPutID] = useState("");
  const [audioPutID, setAudioPutID] = useState("");
  const [imagePutId, setImagePutID] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [eventExists, setEventExists] = useState(true);

  const navigate = useNavigate();


  const [imagee, setImagee] = useState();
  let subtitle;
  let cId = "";
  const { idParams } = useParams();
  console.log("imagee", imagee)

  const idd = idParams;

  async function openConfirmModal(id) {
    console.log("idddddddddd", id);
    await setDeleteId(id);

    setModalIsOpenDelete(true);
  }

  function closeConfirmModal() {
    setModalIsOpenDelete(false);
  }

  function openModal2() {
    setIsOpen2(true);
  }

  function closeModal2() {
    setIsOpen2(false);
  }

  function openModal3() {
    setIsOpen3(true);
  }

  function closeModal3() {
    setIsOpen3(false);
  }

  function openModal4() {
    setIsOpen4(true);
  }

  function closeModal4() {
    setIsOpen4(false);
    setImagee();
  }

  async function openModal5(id) {
    setDescriptionPutID(id);
    await getSingleContent(id);
    await setIsOpen5(true);
  }

  function closeModal5() {
    setIsOpen5(false);
  }

  async function openModal6(id) {
    setAudioPutID(id);
    await getSingleAudio(id);
    await setIsOpenAudio6(true);
  }

  function closeModal6() {
    setIsOpenAudio6(false);
  }

  async function openModal7(id) {
    setImagePutID(id);
    // setImagePutId(id);
    await getSingleImage(id);
    await setIsOpenImage7(true);
  }

  function closeModal7() {
    setIsOpenImage7(false);
  }

  const uploadAudioFile = async (e) => {
    e.preventDefault();

    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an audio.");
      setDisabled(true);
      return;
    }

    if (!file.name.match(/\.(mp3|wav|flac)$/)) {
      setImgError("Please select a valid image. (mp3, wav, flac)");
      setDisabled(true);
      return;
    }

    if (file && file.name.match(/\.(mp3|wav|flac)$/)) {
      await setAudio(file);
      setDisabled(false);
    }
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImagee(URL.createObjectURL(e.target.files[0]));
      setImagePutID()
      setSelectedImage(e.target.files[0]);
      // console.log("ddddd",e.target.files[0])
    }
    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an image.");
      setDisabled(true);
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgError("Please select a valid image. (jpg, jpeg, png)");
      setDisabled(true);
      return false;
    }

    if (file && file.name.match(/\.(jpg|jpeg|png)$/)) {
      await setImage(file);
      setDisabled(false);
    }
  };

  const uploadFileEdit = async (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setImagee(URL.createObjectURL(e.target.files[0]));
      // console.log("ddddd",e.target.files[0])
    }
    let file = e?.target.files[0];

    if (!file) {
      setImgError("Please select an image.");
      setDisabled(true);
      return false;
    }

    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setImgError("Please select a valid image. (jpg, jpeg, png)");
      setDisabled(true);
      return false;
    }

    if (file && file.name.match(/\.(jpg|jpeg|png)$/)) {
      await setImage(file);
      setDisabled(false);
    }
  };

  const uploadImage = async (event) => {

    event.preventDefault();
    let languageValue = event.target[1].value;

    const imageUpload = new FormData();
    imageUpload.append("image", image, image?.name);
    imageUpload.append("lang", languageValue);
    const uploadedImageUrl = languageValue; // Replace with the URL of the uploaded image
    setImagee(uploadedImageUrl);

    try {
      let response = await axios.post(`event-data/image/${idd}`, imageUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Image uploaded Successfully!");
      getImageData();
      setImagee();      // setTimeout(() => {
      closeModal4();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const uploadContent = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    let content = event.target.content.value;
    try {
      let response = await axios.post(`event-data/description/${idd}`, {
        lang: languageValue,
        content: content,
      });
      //   setSuccessCall("Success");
      toast.success("Content uploaded Successfully!");
      getDescriptionData();
      // setTimeout(() => {
      closeModal2();
      // }, 1000);
      console.log(response);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const uploadAudio = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    const audioUpload = new FormData();
    audioUpload.append("audio", audio, audio?.name);
    audioUpload.append("lang", languageValue);
    try {
      let response = await axios.post(`event-data/audio/${idd}`, audioUpload);
      console.log(response);
      //   setSuccessCall("Success");
      toast.success("Audio uploaded Successfully!");
      getAudioData();
      // setTimeout(() => {
      closeModal3();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };


  const getEvent = async () => {
    try {
      let response = await axios.get(`/events/id?id=${idd}`);
      adduserdata(response?.data);
      if (response.data.user_id !== props?.user_id) {
            // window.location.href="/";
            navigate("/");
            return;
      }
    } catch (error) {
        setEventExists(false)
      // toast.error('Something went wrong!')
      console.log(error);
    }
  };

  useEffect(() => {
    getImageData();
    getDescriptionData();
    getAudioData();
    getEvent();
    axios
      .get("languages/all")
      .then((data) => {
        addlanguages(data.data);
        setisloading(true);
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        console.log(err);
      });
  }, [idd, singleData]);


  if (!eventExists) {
    return <Navigate to="/" />;
  }

//   useEffect(()=>{
//     getEvent();
//   },[])

  // const defValues = () => {
  //     const ud = Object.assign({}, userdata);
  //     ud.auth = 'initial-auth';
  //     ud.mobileauth = 'initial-auth';
  //     adduserdata(ud);
  // }

  const eventName = (e) => {
    const ud = Object.assign({}, userdata);
    ud.name = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const def_lang = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.def_lang = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const country = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.country_id = e.target.value;
    console.log("ZZZZZZZZZZZZZZZZZZ", ud.country);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const city = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.city_id = e.target.value;

    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const category = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.category_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const subCategory = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.subcategory_id = e.target.value;
    console.log(ud);
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const active = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.active = e.target.value;
    adduserdata(ud);
    // console.log('seeme',userdata)
  };

  const langID = (e) => {
    // console.log(e)
    const ud = Object.assign({}, userdata);
    ud.lang_id = e.target.value;
    adduserdata(ud);
    console.log("seeme", userdata);
  };
  // const buttonState = () => {
  //     setDisableButton(!disableButton);
  // }

  // console.log('heheh', disableButton);

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("event", {
        user_id: 1,
        ...userdata,
      })
      .then((res) => {
        toast.success("event has been edited successfully!");
        console.log(res);
      })
      .catch((error) => {
        toast.error("Something went wrong!");
        console.log(error);
      });
  };
  // console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz' , Object.keys(userdata).length );
  // console.log(languages);
  //
  // let objectLength = Object.keys(userdata).length;

  const submitCoordinates = async (event) => {
    event.preventDefault();

    try {
      let response = await axios.put("event/location", {
        id: 4,
        ...mapData,
      });
      setMapValidation("Saved");
      console.log(response);
      // setTimeout(() => {
      setMapValidation("");
      // }, 3000);
    } catch (error) {
      setValidationError("Failed");
      console.log(error);
    }
  };


  const getAllCountries = async () => {
    try {
      let response = await axios.get("countries/all");
      setCountries(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAudioData = async () => {
    try {
      let response = await axios.get(`event-data/audio/${idd}`);
      console.log("", response?.data);
      setAudioData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDescriptionData = async () => {
    try {
      let response = await axios.get(`event-data/description/${idd}`);
      setDescriptionData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getImageData = async () => {
    try {
      let response = await axios.get(`event-data/image/${idd}`);
      setImageData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleContent = async (id) => {
    try {
      let response = await axios.get(`event-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };



  const getSingleAudio = async (id) => {
    try {
      let response = await axios.get(`event-data/single/${id}`);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSingleImage = async (id) => {
    try {
      let response = await axios.get(`event-data/single/${id}`);
      console.log("response...................", response.data);
      setSingleData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const editContent = async (event) => {
    event.preventDefault();
    let languageValue = event.target[1].value;
    let content = event.target.content.value;

    try {
      let response = await axios.put(`event-data/description/${descriptonPutID}`, {
        lang: languageValue,
        content: content,
      });
      //   setSuccessCall("Success");
      getDescriptionData();
      getSingleContent(descriptonPutID);
      toast.success("Content has been edited successfully!");
      // setTimeout(() => {
      closeModal5();
      // }, 1000);
      console.log(response);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  console.log("SingleData",singleData)

  const editAudio = async (event) => {
    event.preventDefault();

    let languageValue = event.target[1].value;
    const audioUpload = new FormData();

    audioUpload.append("audio", audio, audio?.name);
    audioUpload.append("lang", languageValue);

    console.log("content content content", audioUpload);

    try {
      let response = await axios.put(`event-data/audio/${audioPutID}`, audioUpload);
      getSingleAudio(cId);
      getAudioData();
      //   setSuccessCall("Success");
      toast.success("Audio has been edited successfully!");
      console.log(response);
      // setTimeout(() => {
      closeModal6();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  const editImage = async (event) => {
    event.preventDefault();

    let languageValue = event.target[1].value;
    const imageUpload = new FormData();

    imageUpload.append("image", image, image?.name);
    imageUpload.append("lang", languageValue);
    try {
      let response = await axios.put(`event-data/image/${imagePutId}`, imageUpload);

      getSingleContent(cId);
      getImageData();
      //   setSuccessCall("Success");
      toast.success("Image has been edited successfully!");
      // setTimeout(() => {
      setImagePutID(null);
      closeModal7();
      // }, 1000);
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };
  console.log("setImagePutId", imagePutId)

  const deleteContent = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`event-data/?id=${id}`);

      getDescriptionData();
      getSingleContent(cId);
      // setTimeout(() => {
      closeModal5();
      // }, 1000);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAudio = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`event-data/?id=${id}`);
      getSingleAudio(cId);
      getAudioData();
      console.log(response);
      toast.success("Audio has been deleted successfully!");
      // setTimeout(() => {
      closeModal6();
      // }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteImage = async (event, id) => {
    event.preventDefault();

    try {
      let response = await axios.delete(`event-data/?id=${id}`);
      console.log(response);
      getSingleContent(cId);
      getImageData();
      toast.success("Content has been deleted successfully!");
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  // console.log(userdata.longitude)
  //
  //
  // console.log('heh', subCategories)
  // console.log('hjeeh', categories)
  //
  // console.log('userzzzrz,', userdata)
  //
  //
  // console.log(',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,', singleData)

  let id = "";
  const confirmButton = (event, id) => {
    console.log("delete id ", deleteId);
    console.log("log incoming", id);
    id = id;
    if (id == 1) {
      deleteImage(event, deleteId);
      getImageData();
      getAudioData();
      getDescriptionData();
      setSuccessDeletedMsg("Has been deleted successfully");
      // setTimeout(() => {
      closeConfirmModal();
      // }, 2000);
    } else {
      console.log("nuk u fsih kurgjo");
      // setTimeout(() => {
      closeConfirmModal();
      // }, 1000);
    }
  };


  return (
    <PointStyle className={props?.sidebarCollapse !== "collapsed" ? "notCollapsedSideBar text-left" : "collapsedSideBar  mt-0 text-left"}>
      <MainContent collapsedEvent={props?.sidebarCollapse} >
        <div>
          <div className="row buttons-group">
            <h1 className="text-center">Add or Edit Event Data</h1>
            <div className="col-md-12 mb-3 mt-4">

              <h2>Content</h2>
              <div className="row">
                <div className="col-md-12">

                  <TableStyleEvents tableEditPoint className="table-responsive"  >
                    <Table className="custom-table table table-hover" >
                      <Thead>
                        <Tr>
                          <Th className="table-header col-md-3">Content</Th>
                          <Th className="table-header col-md-3">Language</Th>
                          <Th className="table-header col-md-2">Edit</Th>
                          <Th className="table-header col-md-2">Delete</Th>
                          <Th className="table-header col-md-2">
                            <ModalButtonsPosition className=''>
                              <ModalMainButtons onClick={openModal2}><i className="fas fa-plus" /> Add Content</ModalMainButtons>
                            </ModalButtonsPosition>
                          </Th>

                        </Tr>
                      </Thead>
                      <Tbody>
                        {descriptionData?.map((el, i) => {
                            console.log("ell",el)
                          return (
                            <>

                              <Tr>
                                <Td>{el?.content}</Td>
                                <Td>{el?.lang}</Td>
                                <Td> <Link
                                  //  className={"fa fa-edit"}
                                  onClick={() => openModal5(el?.event_data_id)}
                                ><FaEdit /></Link></Td>
                                <Td> <Link
                                  className={"fa fa-trash"}
                                  onClick={() => openConfirmModal(el?.event_data_id)}
                                ></Link></Td>
                                <Td></Td>
                              </Tr>
                            </>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </TableStyleEvents>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <h2>Audio</h2>
              <div className="row">
                <div className="col-md-12">
                  <TableStyleEvents tableEditPoint className="table-responsive"  >
                    <Table className="custom-table table table-hover" >
                      <Thead>
                        <Tr>
                          <Th className="table-header col-md-3">Content</Th>
                          <Th className="table-header col-md-3">Language</Th>
                          <Th className="table-header col-md-2">Edit</Th>
                          <Th className="table-header col-md-2">Delete</Th>
                          <Th className="table-header col-md-2"><ModalButtonsPosition className=''>
                            <ModalMainButtons onClick={openModal3}><i className="fas fa-plus" /> Add Audio</ModalMainButtons>
                          </ModalButtonsPosition></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {audioData?.map((el, i) => {
                          return (
                            <>
                              <Tr>
                                <Td>{el?.data_type_name}</Td>
                                <Td>{el?.data_type}</Td>
                                <Td> <Link
                                  // className={"fa fa-edit"}
                                  onClick={() => openModal6(el?.event_data_id)}
                                ><FaEdit /></Link></Td>
                                <Td> <Link
                                  className={"fa fa-trash"}
                                  onClick={() => openConfirmModal(el?.event_data_id)}
                                ></Link></Td>
                                <Td></Td>
                              </Tr>
                            </>
                          )
                        })}
                      </Tbody>
                    </Table>
                  </TableStyleEvents>
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-3 mt-4">
              <h2>Images</h2>
              <div class=" mt-2">
                <div class="cards-1 section-gray ">
                  {/* <button className="btn-plus" onClick={openModal4}>
              <i className="fas fa-plus"></i>
            </button> */}
                  <ModalButtonsPosition className='me-3 mb-3'>
                    <ModalMainButtons onClick={() => openModal4()}><i className="fas fa-plus " /> Add Image</ModalMainButtons>
                  </ModalButtonsPosition>
                  <div class="row  row-cols-1 row-cols-md-3 row-cols-lg-5 g-3">
                    {imageData?.map((el, i) => {
                      return (
                        <>
                          <div class="col p-2">
                            <div class="card card-blog">
                              <div class="card-image">
                                <a href="#"> <img class="img" src={process.env.REACT_APP_UPLOADS + el?.url} /> </a>
                              </div>
                              <div class="ftr">
                                <div class="d-flex justify-content-space">
                                  <div className="col-6 text-center">
                                    <ModalMainButtons type="submit" onClick={() => openModal7(el?.event_data_id)}>Edit</ModalMainButtons>
                                  </div>
                                  <div className="col-6 text-center mb-3">
                                    <ModalMainButtons type="submit" onClick={() => openConfirmModal(el?.event_data_id)}>Delete</ModalMainButtons>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>
      <div className="d">
        {modalIsOpen5 ? (
          <div className="row modal-table">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>


              {/* EDIT CONTENT MODAL */}
              <Modal
                isOpen={modalIsOpen5}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal5}
                className="mymodal"
                contentLabel="Example Modal"
              >




                <div className="row audio-part">
                  <ModalButtonsPosition>
                    <ModalHandleButtons className="mt-3"
                      onClick={() => closeModal5()}
                    ><FaTimes role="button" />
                    </ModalHandleButtons>
                  </ModalButtonsPosition>
                  <form onSubmit={editContent} className='formAdd' >
                    <div className=" mb-3">
                      <h3>Edit Content</h3>
                    </div>
                    {/* <button onClick={() => closeModal5()}>X</button> */}

                    <div className="col-md-12 ">
                      <div className="mb-3 form-modal ">
                        <span className="text-dark mb-1">Content</span>
                        <textarea
                          className="form-control"
                          name="content"
                          defaultValue={singleData?.content}
                          id="exampleFormControlTextarea1"
                          title="This field should not be left blank."
                          required
                          rows="3"
                        ></textarea>
                      </div>
                      <p>{imgError}</p>
                      <div className=" mb-3">
                        <span className="text-dark mb-1">Options</span>
                        <select
                          className="form-control"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          title="This field should not be left blank."
                        >

                          <option
                            name="lang_id"
                            disabled
                            // defaultValue={singleData?.lang_id}
                            selected
                          >
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el.id)}>{el.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={isDisabled}
                          id="inputGroupFileAddon03"
                        >
                          Edit Content
                        </button>
                      </div> */}
                      <ModalButtonsPosition>
                        <ModalMainButtons type="submit" disabled={isDisabled}>Edit Content</ModalMainButtons>
                      </ModalButtonsPosition>
                      {/* <p className="validation-msg-modal">successCall</p> */}
                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}

        {modalIsOpenAudio6 ? (
          <div className="row modal-table">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* EDIT AUDIO MODAL */}
              <Modal
                isOpen={modalIsOpenAudio6}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal6}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <ModalButtonsPosition>
                    <ModalHandleButtons className="mt-3"
                      onClick={() => closeModal6()}
                    ><FaTimes role="button" />
                    </ModalHandleButtons>
                  </ModalButtonsPosition>
                  <form onSubmit={editAudio} className='formAdd'>
                    <div className="">
                      <h3>Edit Audio</h3>
                    </div>
                    <div className="col-md-12">
                      <div className="audio-player">
                        <audio controls>
                          <source
                            src={process.env.REACT_APP_UPLOADS + singleData?.url}
                            type="audio/ogg"
                          />
                        </audio>
                      </div>
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="audio"
                          onChange={uploadAudioFile}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          // required
                          title="This field should not be left blank."
                        />
                      </div>
                      <p>{imgError}</p>
                      <div>
                        <span className="text-dark mb-1">Options</span>
                        <select
                          className="form-control"
                          defaultValue={singleData?.lang_id}
                          id="inputGroupSelect01"
                          title="This field should not be left blank."
                        >
                          <option name="lang" disabled selected>
                            Select Language
                          </option>
                          {languages?.map((el) => {
                            return (
                              <option value={Number(el?.id)}>{el?.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      <p></p>
                      {/* <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={isDisabled}
                          id="inputGroupFileAddon03"
                        >
                          Upload Audio
                        </button>
                      </div> */}
                      <ModalButtonsPosition>
                        <ModalMainButtons type="submit" >Edit Audio</ModalMainButtons>
                      </ModalButtonsPosition>
                      {/* <p className="validation-msg-modal">{successCall}</p> */}
                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}


        {modalIsOpenImage7 ? (
          <div className="row audio-part">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* EDIT IMAGE MODAL */}
              <Modal
                isOpen={modalIsOpenImage7}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeModal7}
                className="mymodal"
                contentLabel="Example Modal"
              >
                <div className="row audio-part">
                  <ModalButtonsPosition>
                    <ModalHandleButtons className="mt-3"
                      onClick={() => closeModal7()}
                    ><FaTimes role="button" />
                    </ModalHandleButtons>
                  </ModalButtonsPosition>
                  {/* <form onSubmit={editImage} className='formAdd'>

                    <div className="mb-3">
                      <h4>Edit Image</h4>
                    </div>
                    {/* <button onClick={() => closeModal7()}>X</button> */}


                  {/* <div className="col-md-12">
                      <div className="input-group mb-3">
                        <input
                          type="file"
                          className="form-control"
                          id="inputGroupFile03"
                          name="image"
                          onChange={uploadFileEdit}
                          aria-describedby="inputGroupFileAddon03"
                          aria-label="Upload"
                          title="This field should not be left blank."
                          required
                        />
                      </div>
                      <p>{imgError}</p> */}

                  {/* <div className="button-left">
                        <button
                          className="btn btn-outline-secondary submit-button"
                          type="submit"
                          disabled={false}
                          id="inputGroupFileAddon03"
                        >
                          Upload Image
                        </button>
                      </div> */}
                  {/* <ModalButtonsPosition>
                        <ModalMainButtons type="submit" >Upload Image</ModalMainButtons>
                      </ModalButtonsPosition> */}

                  {/* <p className="validation-msg-modal">{successCall}</p> */}
                  {/* </div>
                  </form>  */}


                  <form onSubmit={editImage} className="formAdd">
                    <div className="">
                      <h4 className="mb-5 text-center">Edit Image</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-12">
                        <div className="text-center mb-4">
                          {imagee ? (
                            <img src={imagee} width="300" height="300" alt="Uploaded Image" />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mx-auto">
                      <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                        <input
                          id="upload"
                          type="file"
                          onChange={uploadFileEdit}
                          className="form-control border-0"
                        />
                        <label
                          id="upload-label"
                          htmlFor="upload"
                          className="font-weight-light text-muted"
                        >
                          Choose file to edit
                        </label>
                        <div className="input-group-append">
                          <label
                            htmlFor="upload"
                            className="btn btn-light m-0 rounded-pill p-3"
                          >
                            <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                            <small className="text-uppercase font-weight-bold text-muted">
                              Choose file
                            </small>
                          </label>
                        </div>
                      </div>
                      <p>{imgError}</p>


                      <ModalButtonsPosition className="justify-content-center">
                        <ModalMainButtons type="submit" >Edit</ModalMainButtons>
                      </ModalButtonsPosition>

                    </div>
                  </form>
                </div>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}


        {modalIsOpenDelete ? (
          <div className="row ">
            <div className="col-md-12">
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

              {/* DELETE MODAL */}
              <Modal
                isOpen={modalIsOpenDelete}
                // onAfterOpen={afterOpenModal2}
                onRequestClose={closeConfirmModal}
                className="mymodal"
                contentLabel="Example Modal"
              >


                <ModalStyle deletePoint>
                  <div className="text-center">
                    <FaExclamationTriangle className='display-3 text-danger mb-5' />
                    <h3>Delete Point</h3>
                    <h4 className='text-secondary'>You're going to delete the "Point" Data.<br /> Are you sure?</h4>
                  </div>
                  <ModalButtonsPosition className='justify-content-between mt-5'>
                    <ModalMainButtons closeDeleteButton onClick={(event) => confirmButton(event, 0)}>No, Keep it.</ModalMainButtons>
                    <ModalMainButtons type="submit" deleteModal onClick={(event) => confirmButton(event, 1)}>Yes, Delete! </ModalMainButtons>
                  </ModalButtonsPosition>
                </ModalStyle>
              </Modal>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="row modal-table">
        <div className="col-md-12">
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

          {/* ADD CONTENT MODAL */}
          <Modal
            isOpen={modalIsOpen2}
            // onAfterOpen={afterOpenModal2}
            onRequestClose={closeModal2}
            className="mymodal"
            contentLabel="Example Modal"
          >
            <div className="row audio-part">
              <ModalButtonsPosition>
                <ModalHandleButtons className="mt-3"
                  onClick={() => closeModal2()}
                ><FaTimes role="button" />
                </ModalHandleButtons>
              </ModalButtonsPosition>
              <form onSubmit={uploadContent} className="formAdd">
                <div className="mb-3">
                  <h3>Content</h3>
                </div>
                <div className="col-md-12">
                  {/* <h1 className="title-part">Content</h1> */}
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    >
                    </label>
                    <textarea
                      className="form-control"
                      name="content"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Add Content"
                    ></textarea>
                  </div>
                  <p>{imgError}</p>
                  <div className="mb-3">
                    <span className="text-dark pb-2 ">Options</span>
                    <select
                      className="custom-select form-control"
                      id="inputGroupSelect01"
                      title="This field should not be left blank."
                      required
                    >
                      <option name="lang" disabled selected>
                        Select Language
                      </option>
                      {languages?.map((el) => {
                        return <option value={Number(el.id)}>{el.name}</option>;
                      })}
                    </select>
                  </div>

                  <ModalButtonsPosition>
                    <ModalMainButtons type="submit">Add Content</ModalMainButtons>
                  </ModalButtonsPosition>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
      <div>
        <div className="row modal-table">
          <div className="col-md-12">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

            {/* UPLOAD AUDIO MODAL */}
            <Modal
              isOpen={modalIsOpen3}
              // onAfterOpen={afterOpenModal2}
              onRequestClose={closeModal3}
              className="mymodal"
              contentLabel="Example Modal"
            >
              <div className="row audio-part">
                <ModalButtonsPosition>
                  <ModalHandleButtons className="mt-3"
                    onClick={() => closeModal3()}
                  ><FaTimes role="button" />
                  </ModalHandleButtons>
                </ModalButtonsPosition>
                <form onSubmit={uploadAudio} className="formAdd">
                  <div className=" mb-3">
                    <h3>Upload Audio</h3>
                  </div>
                  <div className="col-md-12">
                    {/* <h1 className="title-part">Upload Audio</h1> */}
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile03"
                        name="audio"
                        onChange={uploadAudioFile}
                        aria-describedby="inputGroupFileAddon03"
                        aria-label="Upload"
                        title="This field should not be left blank."
                        required
                      />
                    </div>
                    <p>{imgError}</p>
                    <div className=" mb-3">
                      <span className="text-dark pb-2">Options</span>
                      <select
                        className="custom-select form-control"
                        id="inputGroupSelect01"
                        title="This field should not be left blank."
                        required
                      >
                        <option name="lang" disabled selected>
                          Select Language
                        </option>
                        {languages?.map((el) => {
                          return (
                            <option value={Number(el.id)}>{el.name}</option>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div className="button-left">
                      <button
                        className="btn btn-outline-secondary submit-button"
                        disabled={isDisabled}
                        type="submit"
                        id="inputGroupFileAddon03"
                      >
                        Upload
                      </button>
                    </div> */}
                    <ModalButtonsPosition>
                      <ModalMainButtons type="submit">Upload Audio</ModalMainButtons>
                    </ModalButtonsPosition>
                    {/* <p className="validation-msg-modal">{successCall}</p> */}
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>

        <div className="row audio-part">
          <div className="col-md-12">
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

            {/*UPLOAD IMAGE MODAL  */}
            <Modal
              isOpen={modalIsOpen4}
              // onAfterOpen={afterOpenModal2}
              onRequestClose={closeModal4}
              className="mymodal"
              contentLabel="Example Modal"
            >
              <div className="row audio-part">
                <ModalButtonsPosition>
                  <ModalHandleButtons className="mt-3"
                    onClick={() => closeModal4()}
                  ><FaTimes role="button" />
                  </ModalHandleButtons>
                </ModalButtonsPosition>
                <form onSubmit={uploadImage} className="formAdd">
                  <div className="">
                    <h4 className="mb-5 text-center">Upload Image</h4>
                  </div>
                  <div className="col-md-12">
                    <div className="col-md-12">
                      <div className="text-center mb-4">
                        {imagee ? (
                          <img src={imagee} width="300" height="300" alt="Uploaded Image" />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mx-auto">
                    <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
                      <input
                        id="upload"
                        type="file"
                        onChange={uploadFile}
                        className="form-control border-0"
                      />
                      <label
                        id="upload-label"
                        htmlFor="upload"
                        className="font-weight-light text-muted"
                      >
                        Choose file
                      </label>
                      <div className="input-group-append">
                        <label
                          htmlFor="upload"
                          className="btn btn-light m-0 rounded-pill p-3"
                        >
                          <i className="fa fa-cloud-upload mr-2 text-muted"></i>
                          <small className="text-uppercase font-weight-bold text-muted">
                            Choose file
                          </small>
                        </label>
                      </div>
                    </div>
                    <p>{imgError}</p>


                    <ModalButtonsPosition className="justify-content-center">
                      <ModalMainButtons type="submit" >Add Image</ModalMainButtons>
                    </ModalButtonsPosition>

                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </PointStyle>
  );
};
const mapStateToProps = (state) => {
  return {
    selected_lang: state.data.selected_lang,
    logged_in: state.data.logged_in,
    sidebarCollapse: state?.data?.sidebar,
    user_id: state?.data?.user_id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSideBar: (data) => dispatch(setSideBar(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);
