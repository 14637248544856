import React, { useEffect, useState } from 'react';
import axios from '../../axios';
import {
    ModalStyle, ModalHandleButtons, ModalClose, ModalForm,
    ModalMainButtons, ModalButtonsPosition, ModalWholePage, ModalButtons
} from "../styles/ModalStyle"
import { FaTimes, FaExclamationTriangle } from "react-icons/fa";
import ModalPortal from "../../portal/ModalPortal";
import { toast } from "react-toastify";

const deleteEventModal = (props) => {
    console.log("props",props)
    const handleSubmit = (e) => {
        e.preventDefault();
        axios
            .delete(`/point-opening/${props?.eventData.id}`)
            .then((res) => {
                toast.success("Feature has been deleted successfully!");
                props?.getData();
                props?.handleModalClose();
            })
            .catch((error) => {
                toast.error("Something went wrong!");
                console.log(error);
            });
    };


    if (props?.isOpen) {
        return (
            <>
                <ModalPortal>
                    <ModalWholePage onClick={() => props.handleModalClose()} />
                    <ModalStyle deletePoint>
                        <div className="text-center">
                            <FaExclamationTriangle className='display-3 text-danger mb-5' />
                            <h3>Delete Day</h3>
                            <h5 className='text-secondary'>You're going to delete "{props?.eventData?.week?.name}".<br /> Are you sure?</h5>
                        </div>
                        <ModalButtonsPosition className='justify-content-between mt-5'>
                            <ModalMainButtons closeDeleteButton onClick={() => props?.handleModalClose()}>No, Keep it.</ModalMainButtons>
                            <ModalMainButtons type="submit" deleteModal onClick={(e) => handleSubmit(e)}>Yes, Delete! </ModalMainButtons>
                        </ModalButtonsPosition>
                    </ModalStyle>
                </ModalPortal>
            </>
        )
    }
}

export default deleteEventModal;